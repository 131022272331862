const MainFooter = () => {
  return (
    <>
      <div className="flex justify-center main-title text-center mt-44 relative">
        <img
          src="./static/footer-gift.png"
          alt="footer-gift"
          style={{
            transform: "rotate(-15deg)",
            position: "absolute",
            top: "330px",
          }}
          width={"441px"}
          height={"441px"}
        />
        <div className="leading-1">
          <p className="text-white text-[100px] leading-tight">
            ДАВАЙТЕ БУДЕМ ТВОРИТЬ
          </p>
          <p className="text-[#3F44D9] text-[100px] leading-tight">
            ДОБРО ВМЕСТЕ
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-[97px] mb-[159px]"></div>
    </>
  );
};

export default MainFooter;
