const InstructionContent = () => {
  return (
    <div className="w-[990px] pt-[100px] pb-[100px] pl-[207px] pr-[207px] bg-white rounded-2xl flex justify-center items-center">
      <div className="text-[22px]">
        <p>Спасибо, что решил поучаствовать в акции! </p>
        <br />
        <p>Что делать дальше: </p>
        <br />
        <p>
          ⭐️1 — Оформить доставку в нижегородский офис (для сотрудников из
          других городов) или принести подарок в HR-отдел до 13 декабря.
        </p>
        <p>
          ⭐️2 — Важно красиво упаковать подарок в коробку или оберточную
          бумагу.
        </p>
        <p>
          ⭐️3 — Сверху необходимо оставить записку (стикер для организаторов) с
          именем и возрастом ребенка, кому предназначается подарок и указать от
          кого (например, Наталья, Компания Моризо Диджитал)
        </p>
        <p>
          ⭐️4 — Будет здорово, если ты добавишь к подарку записку для
          маленького получателя от себя: с пожеланием, напутствием и любыми
          другими приятными словами.
        </p>
        <p>
          ⭐️5 — Если ты находишься в другом городе, то можно оформить заказ на
          маркетплейсе с доставкой в Нижегородский офис, а мы поможем с
          упаковкой подарков на месте. Также можно отправить готовый упакованный
          подарок курьером. Доставку в Нижний Новгород мы берем на себя.
        </p>
        <br />
        <p>
          По всем вопросам обращайтесь к{" "}
          <a href="https://t.me/katerinashepetova" target="_blank">
            {" "}
            <span style={{ color: "#3F44D9" }}>Кате</span>
          </a>{" "}
          и{" "}
          <a href="https://t.me/arrr04ka" target="_blank">
            {" "}
            <span style={{ color: "#3F44D9" }}>Арине</span>
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default InstructionContent;
