import { FC } from "react";

interface HexagonProps {
  num: string;
}

const Hexagon: FC<HexagonProps> = ({ num }) => {
  return (
    <div>
      <div className="text-center">
        <img
          className="absolute rounded-3xl"
          src="./static/sixtangle.png"
          alt="hexagon"
          width={"164.5px"}
        />
        <p className="main-card-hexagon-text absolute ml-[37px] text-white text-[64px]">
          {num}
        </p>
      </div>
    </div>
  );
};

export default Hexagon;
