const MainTitle = () => {
  return (
    <>
      <div className="flex justify-center py-8">
        <img src="./static/morizo.png" alt="header" />
      </div>
      <div className="flex justify-center main-title">
        <div className="mt-20">
          <img
            className="absolute left-[38%] top-[15%] rotate-[20deg]"
            src="./static/header-gift.png"
            alt="ball"
            width={120}
          />
          <img
            className="absolute right-[33%] top-[39%] z-[-1]"
            src="./static/header-ledenec.png"
            alt="blick"
            width={105}
          />
          <img
            className="absolute right-[44%] top-[45%] z-[-1] rotate-[45deg]"
            src="./static/header-nosok.png"
            alt="bird"
            width={99}
          />
          <img
            className="absolute left-[25%] top-[26%] z-[-1] scale-x-[-1] rotate-[-20deg]"
            src="./static/header-snowman.png"
            alt="stick"
            width={191}
          />
          <img
            className="absolute left-[56%] top-[20.5%]"
            src="./static/header-star.png"
            alt="snowflake"
            width={92}
          />
          <p className="font-medium text-4xl font-bold tracking-tight text-white sm:text-9xl">
            ТВОРИ
          </p>
          <p className="font-medium text-4xl text-[#3F44D9] font-bold tracking-tight sm:text-9xl">
            ДОБРО
          </p>
        </div>
      </div>
    </>
  );
};

export default MainTitle;
