import { FC, useEffect, useRef, useState } from "react";
import Modal from "../modal/Modal";
import KidsCard from "./KidsCard";
import SelectKindContent from "../modal/SelectKindContent";
import ThankYouContent from "../modal/ThankYouContent";
import { Letter, UserInfo } from "../../types";
import { getLetterById, getLetters } from "../../api";
import ImageContent from "../modal/ImageContent";
import InstructionContent from "../modal/Instruction";

interface KidsCardsProps {
  user: UserInfo | undefined;
}

const KidsCards: FC<KidsCardsProps> = ({ user }) => {
  const params = new URLSearchParams(window.location.search);
  const letter = params.get("letter");

  const [letters, setLetters] = useState<Letter[]>([]);

  const [selectedLetter, setSelectedLetter] = useState<Letter | null>(null);

  const [openSelectKind, setOpenSelectKind] = useState<boolean>(false);
  const cancelButtonSelectKindRef = useRef(null);

  const [openThankYou, setOpenThankYou] = useState<boolean>(false);
  const cancelButtonThankYouRef = useRef(null);

  const [openRules, setOpenRules] = useState<boolean>(false);
  const cancelButtonRulesRef = useRef(null);

  const [openImage, setOpenImage] = useState<boolean>(false);
  const cancelButtonImageRef = useRef(null);

  const handleOpenSelectKind = () => {
    setOpenSelectKind(!openSelectKind);
  };

  const handleOpenThankYou = () => {
    setOpenThankYou(!openThankYou);
  };

  const handleOpenRules = () => {
    setOpenRules(!openRules);
  };

  const handleOpenImage = () => {
    setOpenImage(!openImage);
  };

  useEffect(() => {
    if (letter) {
      if (user?.uid) {
        getLetterById(letter).then((response) => {
          setSelectedLetter(response.data);
          setTimeout(() => {
            user.uid === letters[parseInt(letter) - 1]?.user_uid &&
              handleOpenSelectKind();
          }, 100);
        });
      }
    }
  }, [letter, letters]);

  useEffect(() => {
    getLetters().then((response) => {
      setLetters(response.data);
    });
  }, [openThankYou]);

  useEffect(() => {
    selectedLetter && !openImage && handleOpenSelectKind();
  }, [openImage]);

  return (
    letters && (
      <>
        <div className="flex justify-center mt-24 mb-48" id="cards">
          <div className="grid grid-cols-4 gap-8">
            {letters.map((item, key) => {
              return (
                <KidsCard
                  key={`kids-card-${key}`}
                  setOpen={() => {
                    getLetterById(item.id)
                      .then((response) => {
                        setSelectedLetter(response.data);
                      })
                      .then(() => {
                        handleOpenSelectKind();
                      });
                  }}
                  id={item.id}
                  age={item.age}
                  name={item.name}
                  user={item.user}
                  letterUserId={user?.uid as string}
                />
              );
            })}
          </div>
        </div>
        <Modal
          cancelButtonRef={cancelButtonSelectKindRef}
          open={openSelectKind}
          setOpen={setOpenSelectKind}
          children={
            <SelectKindContent
              setOpenThankYouContent={handleOpenThankYou}
              setOpenImageContent={handleOpenImage}
              setOpen={handleOpenSelectKind}
              letter={selectedLetter}
              hasLetter={user?.has_letter}
              setInstructionContent={handleOpenRules}
              meId={user?.uid}
            />
          }
        />
        <Modal
          cancelButtonRef={cancelButtonThankYouRef}
          open={openThankYou}
          setOpen={setOpenThankYou}
          children={
            <ThankYouContent
              setOpenRules={handleOpenRules}
              setOpen={handleOpenThankYou}
              userName={user?.name as string}
              kidAge={selectedLetter?.age as string}
              kidName={selectedLetter?.name as string}
            />
          }
        />
        <Modal
          cancelButtonRef={cancelButtonRulesRef}
          open={openRules}
          setOpen={setOpenRules}
          children={<InstructionContent />}
        />
        <Modal
          cancelButtonRef={cancelButtonImageRef}
          open={openImage}
          setOpen={setOpenImage}
          children={
            <ImageContent
              setOpenImage={handleOpenImage}
              src={selectedLetter?.image as string[]}
            />
          }
        />
      </>
    )
  );
};

export default KidsCards;
