import React, { FC } from "react";

interface RulesContentProps {
  setOpen: () => void;
}

const RulesContent: FC<RulesContentProps> = ({ setOpen }) => {
  setTimeout(() => {
    const rulesDialog = document.getElementById("dialog");
    rulesDialog?.scroll(0, 0);
  });

  return (
    <div id="rules-modal">
      <div className="absolute left-[50%] ml-[-41px] top-[-20px]">
        <img src="./static/moroz.png" alt="Дед Мороз" />
      </div>
      <div className="w-[990px] h-[1352px] bg-white pt-[73px] pl-[206px] pr-[206px]">
        <div className="flex flex-col items-center">
          <p className="main-title text-[36px] font-medium font-bold">
            Как стать
          </p>
          <p className="main-title text-[36px] font-medium font-bold">
            Дедом Морозом?
          </p>
        </div>
        <div className="pt-[44px]">
          <p className="text-[18px] pb-[44px]">
            <pre>
              <b>1. Как принять участие?</b>
            </pre>
            В этом году письма Дедушке Морозу написали 34 ребенка. Вы можете
            открыть разные карточки и выбрать одно или несколько писем, которые
            вам больше всего откликаются. Чтобы закрепить подарок за собой,
            нужно нажать кнопку «Выбрать подарок». Поменять карточку будет уже
            нельзя.
          </p>
          <p className="text-[18px] pb-[44px]">
            <pre>
              <b>2. Что делать после выбора подарка?</b>
            </pre>
            Сбор подарков будет организован в Нижегородском офисе. Если вы
            находитесь в другом городе, это тоже не проблема! Оформляйте заказ
            на маркетплейсе с доставкой в офис (Нижний Новгород, ул.
            Алексеевская, 6/16), а мы поможем с упаковкой подарков на месте. По
            всем вопросам обращайтесь в HR-отдел к Поповой Екатерине.
            <span style={{ color: "#3F44D9" }}>Важно! </span> Будет здорово,
            если вы приложите к своему подарку записку для маленького
            получателя: с пожеланием, напутствием и любыми другими приятными
            словами. Также можно указать ваше имя (например, Наталья, Компания
            Morizo). А еще будет отлично, если вы самостоятельно упакуете
            подарок в красивую бумагу/подарочный пакет.
          </p>
          <p className="text-[18px] pb-[44px] pt-[44px]">
            <pre>
              <b>3. Когда крайний срок принести подарок?</b>
            </pre>
            Подарки нужно принести в офис до 13 декабря. К ребятам они уедут
            20-25 декабря. Будем держать вас в курсе :)
          </p>
          <div className="flex flex-col items-center">
            <button
              onClick={setOpen}
              type="button"
              className="mt-8 inline-block rounded-full px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out bg-[#3F44D9] w-[199px] h-[56px] text-[16px]"
            >
              ЯСНО-ПОНЯТНО 👌
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesContent;
